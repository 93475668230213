import React from "react";
import { graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import HeroStacked from "../components/Hero/HeroStacked";
import Testimonials from "../components/Repeating/Testimonials";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Business Bookkeeping Services | Smart Accounting Hub"
            description="At Smart Accounting Hub, we provide quality business bookkeeping services to business owners in San Diego and beyond. Learn more & get a quote today!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroStacked
            image={data.hero.childImageSharp.gatsbyImageData}
            backgroundFixed={true}
            imageMaxHeight="max-h-[468px]"
            heading="Business Bookkeeping Services"
            subtext="Busy business owners need skilled bookkeepers to thrive. Lean on our expertise so your business can get more efficient and profitable."
            textMaxWidth="max-w-4xl"
         />

         <section className="py-20 md:py-32">
            <div className="container">
               <div className="grid grid-cols-1 md:grid-cols-2 gap-y-12 md:gap-x-10 lg:gap-x-20 items-center">
                  <div>
                     <GatsbyImage image={data.intro.childImageSharp.gatsbyImageData} alt="Intro" />
                  </div>
                  <div>
                     <h2>A Business Bookkeeper You Can Lean On</h2>
                     <p>
                        Getting organized financially can make or break a small business. At Smart Accounting Hub, we provide quality business
                        bookkeeping services to business owners in San Diego and beyond. We have the experience, expertise, and friendly service you
                        need to get organized for present and future growth. From reconciling accounts to providing reports, we can handle everything
                        you need.
                     </p>
                     <ButtonSolid modal="modal-contact" text="Get a Quote" />
                  </div>
               </div>
            </div>
         </section>

         <section className="pb-20 md:pb-32">
            <div className="container">
               <header className="mb-12 md:mb-16">
                  <h2>Our Business Bookkeeping Services</h2>
               </header>
               <div className="grid md:grid-cols-2 gap-y-10 md:gap-x-10 lg:gap-x-20 items-center">
                  <div>
                     <h3 className="text-lg mb-2">Getting Started</h3>
                     <p className="mb-0">
                        We start by reconciling monthly accounts, entering all credit card statements and receipts, and reviewing and tracking all
                        payments.
                     </p>
                  </div>
                  <div>
                     <h3 className="text-lg mb-2">Multimedia Organization</h3>
                     <p className="mb-0">
                        We’ll digitize all paper documents, receipts, and records, and provide a year-end organized paper filing copy for your
                        convenience.
                     </p>
                  </div>
                  <div>
                     <h3 className="text-lg mb-2">Balancing Books</h3>
                     <p className="mb-0">
                        We reconcile all monthly account statements and receipts to keep track of all financials. With our help, your business’s books
                        stay accurate and organized.
                     </p>
                  </div>
                  <div>
                     <h3 className="text-lg mb-2">Year-End Convenience</h3>
                     <p className="mb-0">
                        We provide year-end profit and loss statements, balance sheets, and any other financial reports or records your accountant
                        needs in digital format.
                     </p>
                  </div>
               </div>
            </div>
         </section>

         <WhyUs />

         <Testimonials className="pt-20 md:pt-32 mb-20 md:mb-32" />

         <section className="pb-20 md:pb-32">
            <div className="container">
               <header className="mb-14 md:mb-18">
                  <h2>Related Services</h2>
               </header>
               <div className="grid md:grid-cols-3 md:gap-x-10 gap-y-12">
                  <div className="group relative">
                     <div className="overflow-hidden mb-6">
                        <GatsbyImage
                           image={data.personalBookkeeping.childImageSharp.gatsbyImageData}
                           alt="Personal Bookkeeping Services
                           "
                           className="transform scale-100 md:group-hover:scale-110 transition-all duration-500 ease-linear"
                        />
                     </div>
                     <h3 className="text-lg mb-2">Personal Bookkeeping</h3>
                     <p className="mb-0">With our personal bookkeeping, stay on track personally so you can thrive professionally.</p>
                     <AniLink fade to="/personal-bookkeeping-services/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>
                  <div className="group relative">
                     <div className="overflow-hidden mb-6">
                        <GatsbyImage
                           image={data.businessManagement.childImageSharp.gatsbyImageData}
                           alt="Business Management Services"
                           className="transform scale-100 md:group-hover:scale-110 transition-all duration-500 ease-linear"
                        />
                     </div>
                     <h3 className="text-lg mb-2">Business Management</h3>
                     <p className="mb-0">We organize or create new processes to help your business operations be more efficient.</p>
                     <AniLink fade to="/small-business-management-services/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>
                  <div className="group relative">
                     <div className="overflow-hidden mb-6">
                        <GatsbyImage
                           image={data.financialReporting.childImageSharp.gatsbyImageData}
                           alt="Financial Reporting"
                           className="transform scale-100 md:group-hover:scale-110 transition-all duration-500 ease-linear"
                        />
                     </div>
                     <h3 className="text-lg mb-2">Financial Reporting</h3>
                     <p className="mb-0">Because cash flow management is more important than a bank balance or revenue growth.</p>
                     <AniLink fade to="/financial-reporting-services/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>
               </div>
            </div>
         </section>

         <CallToAction subText="Get business bookkeeping services you can depend on. Call or request a quote today!" />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Business Bookeeping.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Business Bookeeping.jpg" }) {
         publicURL
      }
      hero: file(relativePath: { eq: "services/Business Bookkeeping/1.0 Business Bookkeeping.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      intro: file(relativePath: { eq: "services/Business Bookkeeping/2.0 Business Bookkeeping Intro.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      personalBookkeeping: file(relativePath: { eq: "services/Personal Bookkeeping Services.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      businessBookkeeping: file(relativePath: { eq: "services/Business Bookkeeping.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      businessManagement: file(relativePath: { eq: "services/Business Management Services.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      financialReporting: file(relativePath: { eq: "services/Financial Reporting.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
   }
`;
export default Page;
